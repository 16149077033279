export const objToQueryString = function(obj) {
    const keyValuePairs = [];
    for (let i = 0; i < Object.keys(obj).length; i += 1) {
        let value = Object.values(obj)[i];
        if (Array.isArray(value)) {
            // is an array? then contain multiple values
            value = value.map(item => encodeURIComponent(item)).join(',');
        } else {
            value = encodeURIComponent(value);
        }
        keyValuePairs.push(`${encodeURIComponent(Object.keys(obj)[i])}=${value}`);
    }
    return keyValuePairs.join('&');
}
import React from 'react';
// import "flatpickr/dist/themes/material_green.css";
// import "flatpickr/dist/themes/airbnb.css";
import Flatpickr from "react-flatpickr";
import filterObserver from "../observer/FilterService";
// import momentPropTypes from 'react-moment-proptypes';
// import moment from 'moment';

class DatePickerInput extends React.Component {
  constructor(props) {
    super();
 
    this.state = {
      date: new Date()
    };
  }

  render() {
    const { date } = this.state;

    return(
      <div className="position-relative">
        <Flatpickr
          name="appointment_date"
          value={date}
          options={{ minDate: date }}
          className="inputText inputText__icon readonly js-datepicker flatpickr-input"
          // onChange={(_, dateStr) => console.info(dateStr)}
          onChange={dateStr => {
            var newDate = new Date(dateStr);
            this.setState({ dateStr });
            filterObserver.notify({ day: newDate.getDay() })
            console.log(this.state.dateStr)
            // console.log(this.props.dateStr)
            // console.log(newDate.getDay())
          }}
        />
        <span className="input-icon icon-calendar"> </span>
      </div>
    ) 
  }
}

export default DatePickerInput

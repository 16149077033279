import React from 'react';
import filterObserver from '../observer/FilterService';

function GenderList(props) {
  return (
    <div>
      <label className="custom_check">
        <input
          name="gender"
          type="checkbox"
          value={props.gender.value}
          onChange={props.onChange}
        />
        <span className="checkmark"></span> {props.gender.name}
      </label>
    </div>
  );
}

class GenderCheckbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      genders: []
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e) {
    // get selected genders
    let selectedGenders = [];
    if (e.target.checked) {
      // add this gender to the state
      selectedGenders = [...this.state.genders, e.target.value];
    } else {
      // remove unchecked gender from state 
      selectedGenders = this.state.genders.filter(gender => gender !== e.target.value);
    }
    console.log("selectedGenders", selectedGenders);
    // update state
    this.setState({ genders: selectedGenders });
    // inform the filter subscriber
    filterObserver.notify({ gender: selectedGenders });
  }
  render() {
    // console.log(this.props)
    return this.props.genders.map(gender => {
      return <GenderList onChange={this.handleChange} key={gender.name} gender={gender} />;
    });
  }
}

export default GenderCheckbox

import React from "react"
import ContentLoader from "react-content-loader" 

const ArticleLoader = () => (
  <ContentLoader 
    speed={2}
    width={500}
    height={350}
    viewBox="0 0 500 350"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="4" y="314" rx="3" ry="3" width="380" height="8" /> 
    <rect x="6" y="7" rx="0" ry="0" width="379" height="210" /> 
    <rect x="5" y="334" rx="3" ry="3" width="380" height="8" /> 
    <rect x="4" y="295" rx="3" ry="3" width="380" height="8" /> 
    <rect x="5" y="235" rx="3" ry="3" width="380" height="9" /> 
    <rect x="4" y="261" rx="3" ry="3" width="380" height="13" />
  </ContentLoader>
)

export default ArticleLoader
import React from 'react';
import ReactDOM from "react-dom";
import MaterialTable from 'material-table';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';

export default class InsurancesTable extends React.Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.theme = createMuiTheme({
      palette: {
        primary: {
          main: '#7cb342',
        },
        secondary: {
          main: '#4caf50',
        },
      },

    });
    this.state = {
      columns: [
        // { title: 'ID', field: 'id', editable: 'never' },
        { title: 'Name', field: 'name' },
        { title: 'Description', field: 'description' }
      ],
      data: [],
      page: null,
      open: false,
      totalCount: null
    }
  }

  fetchData(query){
    return new Promise((resolve, reject) => {
      // let url = '/api/admin/insurances?'
      let url = `${this.props.api_url}/api/v1/public/insurances?`
      // console.log('params', query)
      url += 'per_page=' + query.pageSize
      url += '&q=' + query.search
      url += '&page=' + (query.page + 1)
      fetch(url)
        .then(res => (res && res.status === 200 && res.ok ? res.json() : Promise.reject(res)))
        .then(data => {
          // console.log('row data', data.insurances)
          // console.log('total count', data.pagination.count)
          resolve({
            data: data.insurances,
            page: data.pagination.page - 1,
            totalCount: data.pagination.count
          })
          // this.setState({
          //   data: data.insurances,
          //   page: data.pagination.page - 1,
          //   totalCount: data.pagination.count
          // });
        })
    })
  }

  render() {
    return (
      <MuiThemeProvider theme={this.theme}>
        <MaterialTable
          title={this.props.title}
          columns={this.state.columns}
          tableRef={this.tableRef}
          actions={[
            {
              icon: 'refresh',
              tooltip: 'Refresh Data',
              isFreeAction: true,
              onClick: () => this.tableRef.current && this.tableRef.current.onQueryChange(),
            }
          ]}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 20, 30, 50],
            exportButton: false,
            sorting: true,
            search: true,
          }}
          data={query =>
            this.fetchData(query)
          }
        />
      </MuiThemeProvider>
    )
  }
}

import React from 'react';
import filterObserver from '../observer/FilterService';

function DegreeList(props) {
  return (
    <div>
      <label className="custom_check">
        <input
          name="degree"
          type="checkbox"
          value={props.degree.name}
          onChange={props.onChange}
        />
        <span className="checkmark"></span> {props.degree.name}
      </label>
    </div>
  );
}

class DegreeCheckbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      degrees: []
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e) {
    // get selected degrees
    let selectedDegree = [];
    if (e.target.checked) {
      // add this degree to the state
      selectedDegree = [...this.state.degrees, e.target.value];
    } else {
      // remove unchecked degree from state 
      selectedDegree = this.state.degrees.filter(degree => degree !== e.target.value);
    }
    // console.log("selectedDegree", selectedDegree);
    // update state
    this.setState({ degrees: selectedDegree });
    // inform the filter subscriber
    filterObserver.notify({ degree: selectedDegree });
  }
  render() {
    // console.log(this.props)
    return this.props.degrees.map(degree => {
      return <DegreeList onChange={this.handleChange} key={degree.name} degree={degree} />;
    });
  }
}

export default DegreeCheckbox

import React from 'react';
import ReactDOM from "react-dom";
// import SimpleImageSlider from "react-simple-image-slider";
import { Slide } from 'react-slideshow-image';


const properties = {
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  indicators: true,
  arrows: true,
  pauseOnHover: true,
  onChange: (oldIndex, newIndex) => {
    console.log(`slide transition from ${oldIndex} to ${newIndex}`);
  }
}

export default class HospitalSliders extends React.Component {
  constructor(props) {
    super(props)
  }

  render () {
    console.log(this.props.images)
    return (
      <div className="slide-container">
        <Slide {...properties}>
          {this.props.images.map(image => (
            <div key={image.id} className="each-slide">
              <div style={{'backgroundImage': `url(${image.url})`}}>
              </div>
            </div>
          ))}
        </Slide>
        
      </div>
    );
  }
}

import React from 'react';
import Skeleton from 'react-loading-skeleton';
import AvatarLoader from "./AvatarLoader";
import Image, { Shimmer } from 'react-shimmer';

export default class DoctorItem extends React.Component {
  render() {
    // console.log(this.props.doctor)
    return (
      <div className=" col-lg-4 col-md-6 col-sm-6">
        <div className="single-doctor-box card-full-height">
          <div className="doctor-image">
            <Image
              src={this.props.doctor.avatar.thumb.url}
              fallback={<Skeleton circle={true} height={120} width={120} />}
              errorFallback={() => (
                <AvatarLoader/>
              )}
              NativeImgProps={{ alt: this.props.doctor.avatar.thumb.url }}
            />

            <a href={`/doctors/${this.props.doctor.slug}`} className="details-btn">
              <i className="fas fa-plus" />
            </a>
          </div>
          <div className="doctor-content">
            <h3>
              <a href={`/doctors/${this.props.doctor.slug}`} className="details-btn">
                {this.props.doctor.full_name || <Skeleton  width={200} />}
              </a>
            </h3>
              
            <span> <i className="fas fa-stethoscope"></i> {this.props.doctor.speciality || <Skeleton  width={200} />} </span>
            <span className="text-success text-bold"> <i className="fas fa-map-pin"></i> {this.props.doctor.practic_location || <Skeleton  width={200} />} </span>

          </div>
          {this.props.doctor.available_for_appointment &&
            <div className="submit-btn">
              <a href={`${this.props.appointment_url}/doctors/${this.props.doctor.slug}/appointments/new`} className="btn-book btn-primary">{this.props.appointment_locale}</a>
            </div>
          }
        </div>
      </div>
    );
  }
}

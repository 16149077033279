import React from 'react';
import ReactDOM from "react-dom";
import SearchBar from './SearchBar';
import SearchResultsList from './SearchResultsList';

export default class SearchContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = { preventHideDropdown: false, showDropdown: false, term: '', doctors: [] }
    this.hideDropdown = this.hideDropdown.bind(this);
    this.showDropdown = this.showDropdown.bind(this);
    this.setPreventHideDropdown = this.setPreventHideDropdown.bind(this);
    this.resetPreventHideDropdown = this.resetPreventHideDropdown.bind(this);
  }

  search(term) {
    this.setState({ term });

    $.ajax({
      url: `autocomplete.json/?term=${term}`,
      method: 'GET',
      success: (data) => { this.setState({
        doctors: data.doctors
      });}
    });
  }

  setPreventHideDropdown() {
    this.setState({ preventHideDropdown: true });
  }

  resetPreventHideDropdown() {
    this.setState({ preventHideDropdown: false });
  }

  hideDropdown() {
    if (!this.state.preventHideDropdown) {
      this.setState({ showDropdown: false });
    }
  }

  showDropdown() {
    this.setState({ showDropdown: true });
  }

  render () {
    return (
      
      <div>
        <SearchBar 
          showDropdown={this.showDropdown}
          hideDropdown={this.hideDropdown}
          term={this.state.term} 
          placeholder={this.props.local}
          onSearchTermChange={(term) => {this.search(term)}}
          // param={this.props.param}
        />
        {this.renderSearchResults()}
      </div>
    );
  }

  renderSearchResults() {
    if(!this.state.showDropdown || (this.state.doctors.length === 0 )) {
      return;
    }
    // console.log(this.state.term)
    // console.log(this.state.doctors)
    return (
      <SearchResultsList
        setPreventHideDropdown={this.setPreventHideDropdown}
        resetPreventHideDropdown={this.resetPreventHideDropdown}
        term={this.state.term}
        doctors={this.state.doctors}
      />
    );
  }
}

import React from 'react';
import ReactDOM from "react-dom";

export default class SearchDoctorListItem extends React.Component {
  render() {
    // console.log(this.props.doctor.avatar_url)
    return (
      <li>
        <a href={this.props.doctor.url}>
          <strong className="d-block"  dangerouslySetInnerHTML={{ __html: this.props.doctor.doctor_name }} />
          <i className="fas fa-stethoscope mr-1"/>
          <span className="small"  dangerouslySetInnerHTML={{ __html: this.props.doctor.speciality }} />
          <hr className="my-0"/>
        </a> 
      </li>
    );
  }
}

import React from 'react';
import Select from 'react-select';
import filterObserver from "../observer/FilterService";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    // borderBottom: '1px dotted pink',
    // color: state.isSelected ? 'red' : 'blue',
  }),
  input: () => ({
    // color: '#ff9800'
  }),
  // placeholder: () => ({
  //   text: 'Choose branch'
  // })

}
class SelectCareerCategory extends React.Component {
  state = {
    selectedOption: null,
  };
  handleChange = selectedOption => {
    this.setState({
        selectedOption
      }, () => {
        // notify the observer
        filterObserver.notify({ category: selectedOption.value })

        console.log(`Option selected speciality:`, this.state.selectedOption)
      });
  };
  render() {
    const {selectedOption} = this.state;
    return ( 
      <Select
        value = {selectedOption}
        onChange = {this.handleChange}
        options = {this.props.categories}
        styles={customStyles}
        placeholder = {this.props.placeholder}
        name = 'category'
        theme={theme => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            // primary25: '#88f7b9',
            primary: '#17CE67',
          },
        })}
      />
    );
  }
}

export default SelectCareerCategory

import React from 'react';
// import "flatpickr/dist/themes/material_green.css";
import {
  FacebookShareCount,
  FacebookShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  LineShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  TelegramIcon,
  WhatsappIcon,
  LineIcon,
} from "react-share";
// import './SocialShare.css';

class SocialShare extends React.Component {
  
  render() {
    const shareUrl = this.props.url;
    const title = this.props.title;
    console.log('url', this.props.url)
    return(
      <div className="Demo__container">
        <div className="Social__share-network">
          <FacebookShareButton
            url={shareUrl}
            quote={title}
            className="Social__share-network__share-button"
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          <div>
            <FacebookShareCount url={shareUrl} className="Social__share-network__share-count">
              {count => count}
            </FacebookShareCount>
          </div>
        </div>

        <div className="Social__share-network">
          <TwitterShareButton
            url={shareUrl}
            title={title}
            className="Social__share-network__share-button"
          >
            <TwitterIcon size={32} round />
          </TwitterShareButton>

          <div className="Social__share-network__share-count">&nbsp;</div>
        </div>
        <div className="Social__share-network">
          <TelegramShareButton
            url={shareUrl}
            title={title}
            className="Social__share-network__share-button"
          >
            <TelegramIcon size={32} round />
          </TelegramShareButton>

          <div className="Social__share-network__share-count">&nbsp;</div>
        </div>

        <div className="Social__share-network">
          <WhatsappShareButton
            url={shareUrl}
            title={title}
            separator=":: "
            className="Social__share-network__share-button"
          >
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>

          <div className="Social__share-network__share-count">&nbsp;</div>
        </div>

        <div className="Social__share-network">
          <LinkedinShareButton url={shareUrl} className="Social__share-network__share-button">
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
        </div>

        <div className="Social__share-network">
          <LineShareButton
            url={shareUrl}
            title={title}
            className="Social__share-network__share-button"
          >
            <LineIcon size={32} round />
          </LineShareButton>
        </div>
      </div>
    ) 
  }
}

export default SocialShare

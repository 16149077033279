import React from 'react';
import ReactDOM from "react-dom";
import MaterialTable from 'material-table';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

export default class ParticipantTable extends React.Component {
  constructor(props) {
    super(props);
    this.theme = createMuiTheme({
      palette: {
        primary: {
          main: '#7cb342',
        },
        secondary: {
          main: '#4caf50',
        },
      },
    });
    this.state = {
      columns: [
        { title: 'Name', field: 'name' },
        { title: 'NIK', field: 'nik' },
        { title: 'Ponsel', field: 'phone_number' },
        { title: 'DOB', field: 'date_of_birth', editable: 'never' },
        { title: 'Phase 1', field: 'phase_one', editable: 'never', type: 'datetime' },
        { title: 'Phase 2', field: 'phase_two', editable: 'never', type: 'datetime' },
        { title: 'Batch', field: 'batch_name', editable: 'never' },
        { title: 'Status', field: 'status', editable: 'never' }
      ],
      data: [],
      page: null,
      totalCount: null
    }

    this.tableRef = React.createRef();
  }

  fetchData(query){
    return new Promise((resolve, reject) => {
      let url = `/api/vaccination/participants/${this.props.company_id}.json?`
      if (this.props.vaccination_id) {
        console.log('vaccination_id', this.props.vaccination_id)
      }
      url += 'per_page=' + query.pageSize
      url += '&q=' + query.search
      url += '&page=' + (query.page + 1)
      if (this.props.vaccination_id) {
        url += '&vaccination_id=' + this.props.vaccination_id
      }
      fetch(url)
        .then(response => response.json())
        .then(result => {
          // console.log('row data', result.accounts.data)
          resolve({
            data: result.participants,
            page: result.pagination.page - 1,
            totalCount: result.pagination.count,
          })
        })
    })
  }

  render () {
    return (
      <MuiThemeProvider theme={this.theme}>
        <MaterialTable
          title="Peserta Vaksinasi"
          tableRef={this.tableRef}
          columns={this.state.columns}
          data={query =>
            this.fetchData(query)
          }

          actions={[
            {
              icon: 'refresh',
              tooltip: 'Refresh Data',
              isFreeAction: true,
              onClick: () => this.tableRef.current && this.tableRef.current.onQueryChange(),
            }
          ]}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 100, 200, 300, 500],
            exportButton: true,
            sorting: true,
            search: true
          }}
        />
      </MuiThemeProvider>
    );
  }
}

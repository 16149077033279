import React from 'react';
// import "flatpickr/dist/themes/material_green.css";
import "flatpickr/dist/themes/airbnb.css";
import Flatpickr from "react-flatpickr";

class DateInput extends React.Component {
  constructor(props) {
    super();
 
    this.state = {
      date: new Date()
    };
  }

  render() {
    const { date } = this.state;
    return(
      <Flatpickr
        name="appointment_date"
        value={date}
        options={{ minDate: date }}
        className="inputText inputText__icon readonly js-datepicker flatpickr-input"
        onChange={(_, dateStr) => console.info(dateStr)}
        // onChange={date => {
        //   this.setState({ date });
        //   console.log(this.state.date)
        //   console.log(this.props.dateStr)
        // }}
      />
    ) 
  }
}

export default DateInput

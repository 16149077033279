import React from 'react';
import Select from 'react-select';
import filterObserver from "../observer/FilterService";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    // borderBottom: '1px dotted pink',
    // color: state.isSelected ? 'red' : 'blue',
  }),
  input: () => ({
    // color: '#ff9800'
  }),
  // placeholder: () => ({
  //   text: 'Choose branch'
  // })

}
class SelectSpeciality extends React.Component {
  state = {
    selectedOption: null,
  };
  handleChange = selectedOption => {
    this.setState({
        selectedOption
      }, () => {
        // notify the observer
        filterObserver.notify({ speciality_id: selectedOption.value })
        var cookieDate = new Date()
        cookieDate.setFullYear(cookieDate.getFullYear() + 1); //1 year from now
        document.cookie = "hermina_user_speciality_id="+selectedOption.value+"; path=/; expires=" + cookieDate.toUTCString() + ";"
        // console.log(`Option selected speciality:`, this.state.selectedOption)
      });
  };
  render() {
    const {selectedOption} = this.state;
    return ( 
      <Select
        value = {selectedOption}
        onChange = {this.handleChange}
        options = {this.props.specialities}
        styles={customStyles}
        placeholder = {this.props.placeholder}
        name = 'speciality_id'
        theme={theme => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            // primary25: '#88f7b9',
            primary: '#17CE67',
          },
        })}
        
      />
    );
  }
}

export default SelectSpeciality

import React from "react"
import Async from "react-async"
// import ArticleLoader from "./loader/ArticleLoader"
import Skeleton from "react-loading-skeleton"
import ArticleItem from "./ArticleItem"
import ArticleLoader from "./loader/ArticleLoader"
import BtnLoadMore from './BtnLoadMore'
import queryString from 'query-string'
import filterObserver from "../observer/FilterService"
import { objToQueryString } from '../plugins/url-utils'

export default class ArticleList extends React.Component {
  constructor(props) {
    super(props);
    // console.log('Article from backend', this.props.articles)
    // console.log('params query string ', location.search)
    console.log('params query string ', location)
    console.log('query string parsed loaction ',queryString.parse(location.search))
    this.state = {
      loading: false,
      totalArticles: 4,
      articles: null,
      error: false,
      nextPage: null 
    };
    // to allow the corresponding method to access 'this' variable
    // this.onFilterUpdate = this.onFilterUpdate.bind(this);
    this.fetchArticles = this.fetchArticles.bind(this);
    this.handleLoadMore = this.handleLoadMore.bind(this);
  }

  componentDidMount() {
    // subscribe to any filter changes
    // filterObserver.attach(this.onFilterUpdate);
    var parsed = queryString.parse(location.search)
    this.fetchArticles(queryString.stringify(parsed), true);
  }

  fetchArticles(queryString, replaceExisting) {
    console.log('params', queryString);
    // make `this` to be always callbale inside different instance
    const _this = this;
    // set loading to true while fetching the data
    this.setState({ loading: true}, () => {
      const url = `/api/articles.json${queryString ? '?' + queryString : ''}`;
      console.log('fetching articles:', url);
      fetch(url)
        .then(res => (res && res.status === 200 && res.ok ? res.json() : Promise.reject(res)))
        .then(data => {
          // console.log("retrieved", data);
          let articles;
          // replace or addition
          if (replaceExisting) {
            articles = data.articles;
          } else {
            // console.log('Articles is', articles)
            articles = [..._this.state.articles, ...data.articles]
            // console.log('articles if replaceExisting false is ', articles)
          }
          // update data
          // console.log('data pagination is', data.pagination)
          _this.setState({totalArticles: articles.length})
          setTimeout(() => {
            // console.log('SET TIMEOUT');
            _this.setState({
              loading: false,
              articles: articles,
              nextPage: data.pagination.next
            });
          }, 600);
        }).catch(res => {
          // error occurred
          console.warn('fetching error occurred', res);
          _this.setState({
            error: res
          });
        }).finally(() => {
          // request finished, set loading to false
          _this.setState({ loading: false })
        });
    });
  }

  generateContent(isLoading, articles, error, totalArticles) {

    if (error) {
      return `Something went wrong: ${error.message}`;
    } else if (articles) {
      // console.log("Articles length is", articles.length)
      if (articles.length) {
        // console.log('Masuk pak eko', isLoading)
        return (
          <div>
            <div className="row">
              {articles.map(article => (
                <ArticleItem key={article.id} article={article} />
              ))}
            </div>
            { isLoading && this.renderLoading(totalArticles) }
          </div>
        );
      } else {
        return (<h3 className="text-center">Nothing to show</h3>);
      }
    } else {
      // console.log('no data')
      return this.renderLoading(totalArticles);
    }
  }

  renderLoading(totalArticles){
    return(
      <div className="row">
        {Array(totalArticles)
          .fill()
          .map((item, index) => (
            <div className="col-lg-3 col-md-6" key={index}>
              <div className="single-blog-post card-full-height">
                <div className="post-image">
                  <Skeleton height={150} width={`100%`} />
                </div>

                <div className="post-content ">
                  <Skeleton height={12} width={`100%`} />
                  <Skeleton height={13} width={`100%`} />
                  
                  <p className="mt-1"><Skeleton height={10} count={3} /></p>
                  
                  
                </div>
              </div>
            </div>
          ))
        }
      </div>
    )
  }

  // renderLoading(totalArticles){
  //   return(
  //     <div className="row">
  //       {Array(totalArticles)
  //         .fill()
  //         .map((item, index) => (
  //           <div className="col-lg-4 col-md-6" key={index}>
  //             <div className="single-blog-post card-full-height">
  //               <ArticleLoader/>
  //             </div>
  //           </div>
  //         ))
  //       }
  //     </div>
  //   )
  // }

  handleLoadMore() {
    let params = {
      page: this.state.nextPage
    };
    console.log("Params is", params)
    params = objToQueryString(params);
    this.fetchArticles(params, false);
  }

  render(){
    const { loading, articles, error, nextPage, totalArticles } = this.state;
    // console.log("Article render", articles);
    return (
      <div className="doctor-wrapper">
        {this.generateContent(loading, articles, error, totalArticles)}
        {nextPage && 
          <div id="div_next_link">
            <div className="load-more text-center">
            </div>
          </div>
        }
      </div>
    )
  }
}

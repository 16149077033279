import React from 'react';
import Image, { Shimmer } from 'react-shimmer';



export default class ArticleItem extends React.Component {
  render() {
    // console.log(this.props.doctor.avatar_url)
    const {scrollPosition} = this.props;
    return (
      <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 d-flex card-full-height">
        <div className="single-blog-post flex-fill">
          <div className="post-image">
            <a href={this.props.article.url}>
              <Image
                src={this.props.article.image_url}
                fallback={<Shimmer width={210} height={210} />}
                errorFallback={() => (
                  <Shimmer width={210} height={210} />
                )}
                NativeImgProps={{ alt: this.props.article.title }}
              />
            </a>
          </div>

          <div className="post-content ">
            <div className="post-meta">
              <ul>
                <li>By: <a href={`branch/${this.props.article.owner}`}>{this.props.article.owner_name}</a></li>
                <li>{this.props.article.created_at}</li>
              </ul>
            </div>

            <h3><a href={this.props.article.url}>{this.props.article.title}</a></h3>
            <p>{this.props.article.content}</p>
          </div>
        </div>
      </div>
    );
  }
}

import React from 'react';
import Select from 'react-select';
import filterObserver from "../observer/FilterService";
// import Cookies from 'universal-cookie';

// const cookies = new Cookies();
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    // borderBottom: '1px dotted pink',
    // color: state.isSelected ? 'red' : 'blue',
  }),
  input: () => ({
    // color: '#ff9800'
  }),
  theme: (theme) => ({
    ...theme,
    borderRadius: 0,
    colors: {
      ...theme.colors,
      primary25: 'hotpink',
      primary: 'black',
    }
  })
  // placeholder: () => ({
  //   text: 'Choose branch'
  // })

}
class SelectBranch extends React.Component {
  state = {
    selectedOption: this.props.default_value,
  };
  handleChange = selectedOption => {
    this.setState({
        selectedOption
      }, () => {
        // notify the observer
        filterObserver.notify({ hospital_id: selectedOption.value })
        var cookieDate = new Date()
        cookieDate.setFullYear(cookieDate.getFullYear() + 1); //1 year from now
        // cookies.set('hermina_user_hospital_id', selectedOption.value, { path: '/', expires: cookieDate.setFullYear(cookieDate.getFullYear() + 1) })
        document.cookie = "hermina_user_hospital_id="+selectedOption.value+"; path=/; expires=" + cookieDate.toUTCString() + ";"
        // console.log(cookies.get('hermina_user_hospital_id'))
        console.log(`Option selected:`, this.state.selectedOption)
        // console.log(`Cookies hospital_id:`, this.state.selectedOption)
      });
  };
  render() {
    // console.log('default value is', this.props.default_value)
    const {selectedOption} = this.state;
    return ( 
      <Select
        value = {selectedOption}
        onChange = {this.handleChange}
        options = {this.props.branches}
        name = 'hospital_id'
        placeholder = {this.props.placeholder}
        theme={theme => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            // primary25: '#88f7b9',
            primary: '#17CE67',
          },
        })}
      />
    );
  }
}

export default SelectBranch

import React from 'react';
import ReactDOM from "react-dom";
import CatalogMagic from './CatalogMagic';


export default class Loader extends React.Component {
  
  render () {
    return (
      <div>
        <CatalogMagic />
      </div>
    );
  }
}

import React from "react"
import ContentLoader from "react-content-loader" 

const CareerLoader = () => (
  <ContentLoader 
    speed={2}
    width={600}
    height={80}
    viewBox="0 0 600 80"
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <rect x="6" y="18" rx="3" ry="3" width="250" height="10" /> 
    <rect x="7" y="38" rx="3" ry="3" width="80" height="10" /> 
    <rect x="8" y="60" rx="3" ry="3" width="550" height="9" /> 
    <rect x="107" y="38" rx="3" ry="3" width="80" height="10" />
  </ContentLoader>
)

export default CareerLoader

import React from 'react';
import ReactDOM from "react-dom";
import SearchDoctorListItem from './SearchDoctorListItem';

export default class SearchResultsList extends React.Component {

  render() {
    return (
      <ul className="dropdown-menu" id="autocomplete-items" onMouseEnter={() => {this.props.setPreventHideDropdown()}} onMouseLeave={() => {this.props.resetPreventHideDropdown()}}>
        <span className="dropdown-arrow-top"></span>
        <span className="dropdown-arrow-bottom"></span>
        <li>
          <a href={`/doctors?q=${this.props.term}`}>
            <i className="fa fa-search"></i> Search for <strong>{this.props.term}</strong>
          </a>
        </li>
        {this.renderDoctorHeading()}
        {this.renderDoctors()}
      </ul>
    );
  }

  renderDoctors() {
   return this.props.doctors.slice(0, 6).map((doctor) => {
      return <SearchDoctorListItem key={doctor.id} doctor={doctor} />
    });
  }

  renderDoctorHeading() {
    // console.log(this.props.doctors)
    if (this.props.doctors.length === 0) { return; }

    return <li className="autocomplete-heading"><h4>Doctors</h4></li>
  }
}

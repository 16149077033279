import React from 'react';

class BtnLoadMore extends React.Component {
  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  }

  render() {
    return(
      <a className="btn btn-primary btn-sm text-white" href="" onClick={this.handleClick}> Load More</a>
    ) 
  }
}

export default BtnLoadMore
import React from "react"
import ReactDOM from "react-dom";
import ContentLoader from "react-content-loader";

export default class DoctorsContentLoader extends React.Component {
  render(){
    return(
      <ContentLoader 
        height={700}
        width={1000}
        speed={2}
        primaryColor="#19ce67"
        secondaryColor="#acf2ca"
      >
        <circle cx="174" cy="115" r="107" /> 
        <rect x="71" y="267" rx="4" ry="4" width="203" height="14" /> 
        <rect x="70" y="240" rx="3" ry="3" width="203" height="14" /> 
        <circle cx="500" cy="115" r="107" /> 
        <rect x="398" y="242" rx="3" ry="3" width="203" height="14" /> 
        <rect x="398" y="268" rx="4" ry="4" width="203" height="14" /> 
        <circle cx="825" cy="116" r="107" /> 
        <rect x="722" y="243" rx="3" ry="3" width="203" height="14" /> 
        <rect x="722" y="269" rx="4" ry="4" width="203" height="14" /> 
        <circle cx="177" cy="446" r="107" /> 
        <rect x="72" y="574" rx="9" ry="4" width="203" height="14" /> 
        <rect x="72" y="602" rx="9" ry="4" width="203" height="14" /> 
        <circle cx="499" cy="448" r="107" /> 
        <rect x="399" y="575" rx="9" ry="4" width="203" height="14" /> 
        <rect x="400" y="601" rx="9" ry="4" width="203" height="14" /> 
        <circle cx="825" cy="447" r="107" /> 
        <rect x="731" y="573" rx="9" ry="4" width="203" height="14" /> 
        <rect x="732" y="602" rx="9" ry="4" width="203" height="14" />
      </ContentLoader>
    ); 
  }
}

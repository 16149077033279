import React from "react"
import ReactDOM from "react-dom";
import ContentLoader from "react-content-loader";

export default class DoctorContentLoader extends React.Component {
  render(){
    return(
     <ContentLoader 
        height={300}
        speed={2}
        primaryColor="#19ce67"
        secondaryColor="#acf2ca"
      >
        <circle cx="136" cy="118" r="107" /> 
        <rect x="34" y="267" rx="4" ry="4" width="203" height="14" /> 
        <rect x="33" y="240" rx="3" ry="3" width="203" height="14" />
      </ContentLoader>
    ); 
  }
}

import React from 'react';
import filterObserver from '../observer/FilterService'

function SpecialityList(props) {
  return (
    <div>
      <label className="custom_check">
        <input 
          name="speciality_id" 
          type="checkbox"
          value= {props.speciality.id}
          onChange={props.onChange}
        />
        <span className="checkmark"></span> {props.speciality.name.toLowerCase()}
      </label>
    </div>
  );
}

class SpecialityCheckbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      specialities: []
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e) {
    // get selected specialities
    let selectedSpecialities = [];
    if (e.target.checked) {
      // add this gender to the state
      selectedSpecialities = [...this.state.specialities, e.target.value];
    } else {
      // remove unchecked gender from state 
      selectedSpecialities = this.state.specialities.filter(speciality => speciality !== e.target.value);
    }
    console.log("selectedSpecialities", selectedSpecialities);
    // update state
    this.setState({ specialities: selectedSpecialities });
    // inform the filter subscriber
    filterObserver.notify({ speciality_id: selectedSpecialities });
  }
  render() {
    // console.log(this.props.specialities)
    return this.props.specialities.map((speciality) => {
      return <SpecialityList onChange={this.handleChange} key={speciality.id} speciality={speciality}/>
    });
  }
}

export default SpecialityCheckbox

import React from "react"
import ContentLoader from "react-content-loader" 

const ArticleThumbnail = () => (
  <ContentLoader 
    speed={2}
    width={400}
    height={475}
    viewBox="0 0 400 475"
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <rect x="10" y="3" rx="2" ry="2" width="324" height="169" />
  </ContentLoader>
)

export default ArticleThumbnail
import React from 'react';
import Select from 'react-select';
import filterObserver from "../observer/FilterService";

class SelectLocale extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: this.props.selected,
      splitedUrl: document.URL.split('/') 
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(selectedOption) {
    console.log(`current Url is:`, this.state.splitedUrl)
    this.setState({
        selectedOption
      }, () => {
        // notify the observer
        filterObserver.notify({ locale: selectedOption.value })
        // Turbolinks.visit('/') 
        var selectedLocale = selectedOption.value
        if (this.state.splitedUrl.length == 4) {
            Turbolinks.visit(selectedLocale) 
        }else{
            // console.log(`current url`, splitedUrl)
            // console.log(`selected locale`, selectedLocale)
            var getLocale = this.state.splitedUrl.indexOf(this.state.splitedUrl[3]);
            if (~getLocale) {
                this.state.splitedUrl[getLocale] = selectedLocale;
            }
            // console.log('Split url after replace',splitedUrl)
            Turbolinks.visit(this.state.splitedUrl.join('/'))
        }
        console.log(`current Url is:`, this.state.splitedUrl)
        console.log(`Option selected:`, this.state.selectedOption)
    });
  };
  render() {
    const {selectedOption} = this.state;
    // console.log('Selected language', selectedOption)
    return ( 
      <select className="switch-language nice-select" onChange={this.handleChange} value={selectedOption.value}>
        {this.props.locales.map(item => (
          <option key={item.value} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    );
  }
}

export default SelectLocale

import React from "react"
import ContentLoader from "react-content-loader" 

const AvatarLoader = () => (
 
  <ContentLoader 
    speed={2}
    width={400}
    height={160}
    primaryColor="#19ce67"
    secondaryColor="#acf2ca"
  >
    <circle cx="206" cy="80" r="78" />
  </ContentLoader>
)

export default AvatarLoader
